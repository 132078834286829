import { ORGANIZATION, TEMPLATES_API } from './constants'

export const API_URL = `${process.env.REACT_APP_AUTHORITY}/api`

export const templatesApiUrl = (alias: string, isPreviewMode: boolean) => {
  return `${TEMPLATES_API}/getsingletemplate?alias=${alias}&organization=${ORGANIZATION}&previewMode=${isPreviewMode}`
}

export const detailsTemplateApiUrl = (
  alias: string,
  isPreviewMode: boolean
) => {
  return `${TEMPLATES_API}/getsingletemplate?alias=${alias}_Details&organization=${ORGANIZATION}&previewMode=${isPreviewMode}`
}
