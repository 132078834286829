import { FC, useEffect } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Helmet } from 'react-helmet';
import { LiveError, LivePreview, LiveProvider } from 'react-live';
import { useUserContext } from "../../context";
import { useOrganizationSettingsContext } from "../../context/OrganizationSettingsContext";
import { useFetch, usePreviewMode, useToggle } from "../../hooks";
import { cx, templatesApiUrl, textareaClasses } from "../../utils";
import { Textarea } from "../textarea";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

interface IContactSupport {
  message: string;
}

export const TermsFooter: FC = () => {
  const { organization } = useOrganizationSettingsContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const { visible, toggle } = useToggle();
  const { isPreviewMode } = usePreviewMode();

  const { apiCall: submitContactRequest, loading } = useFetch("post");
  const {
    apiCall: getTermsFooterTemplate,
    response: termsFooterTemplate,
    loading: termsFooterTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getTermsFooterTemplate(templatesApiUrl('TermsFooter', isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onContactSupportSubmit: SubmitHandler<any> = (
    data: IContactSupport
  ) => {
    const contactSupportObj = {
      ...data,
      email: user?.email,
    };

    submitContactRequest("Feedback/ContactSupport", contactSupportObj, () => {
      toast.success(t<string>("contactSupport.messageSentSuccess"));
      toggle();
      reset();
    });
  };


  const onCloseModal = () => {
    toggle();
    reset();
  }

  const scope = {
    organization,
    t,
    cx,
    visible,
    Icon,
    user,
    Textarea,
    textareaClasses,
    toggle,
    errors,
    register,
    handleSubmit,
    onContactSupportSubmit,
    loading,
    onCloseModal,
  }

  return (
    <>
      <Helmet>
        <style>{termsFooterTemplate?.css}</style>
      </Helmet>
      {!termsFooterTemplateLoading && (
        <LiveProvider code={termsFooterTemplate?.html} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
