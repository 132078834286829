import { RouteProps } from "react-router-dom";
import { Permission } from "../context";
import {
  AuthenticatorApp,
  ChangePassword,
  DeleteAccount,
  DeviceDetail,
  DownloadData,
  EmailAuthenticator,
  ExternalLogins,
  ManageDevices,
  Models,
  MyAccount,
  Payments,
  PaymentsList,
  Permissions,
  Personalization,
  PrivacyPolicy,
  Profile,
  RecoveryCodes,
  RecoveryEmail,
  Security,
  Subscriptions,
  SubscriptionsList,
  Terms,
  TextMessage,
  TwoFactorAuthentication,
} from "../pages";
import { CreateTemplate } from "../pages/CreateTemplate";
import { Gifts } from "../pages/gifts";
import { GiftsList } from "../pages/gifts/GiftsList";
import { ModelItemDetails } from '../pages/models/ModelItemDetails';
import { SiteLicenceList } from "../pages/subscriptions/SiteLicenceList";
import { SiteLicenceUsers } from "../pages/subscriptions/SiteLicenceUsers";
import {
  GIFTS_PATH,
  GIFTS_PATH_LIST,
  MANAGE_DEVICES_PATH,
  MANAGE_PERMISSIONS_PATH,
  PAYMENTS_LIST_PATH,
  PAYMENTS_PATH,
  RECOVERY_EMAIL_PATH,
  SITE_LICENCES_PATH,
  SITE_LICENCE_USERS_PATH,
  SUBSCRIPTIONS_LIST_PATH,
  SUBSCRIPTIONS_PATH,
  TFA_AUTH_APP_PATH,
  TFA_EMAIL_PATH,
  TFA_PATH,
  TFA_RECOVERY_CODES_PATH,
  TFA_TEXT_MESSAGE_PATH,
  featureEnabled,
  myAccountDisabled,
  personalizationDisabled,
  securityDisabled,
  tfaDisabled,
} from "../utils";

const tfaEnabled = featureEnabled["twoFactorAuth"] === true;
const { betaTester } = Permission;

export interface RoutesProps extends RouteProps {
  name?: string;
  permissions?: Permission[];
  primary: boolean;
}

export const routes = [
  // my account
  ...(!myAccountDisabled
    ? [
        {
          path: "/",
          element: <MyAccount />,
          primary: true,
        },
      ]
    : []),

  // Payments
  {
    path: "/payments",
    element: <Payments />,
  },

  // Subscriptions
  {
    path: "/subscriptions",
    element: <Subscriptions />,
  },

  // Personalizations and sub pages
  ...(!personalizationDisabled
    ? [
        {
          path: "/",
          element: <Personalization />,
          // primary: true,
        },
      ]
    : []),

  ...(!personalizationDisabled
    ? [
        {
          path: "/personalization",
          element: <Personalization />,
          primary: true,
        },
      ]
    : []),

  ...(featureEnabled["downloadData"] === true
    ? [
        {
          path: "/download-data",
          element: <DownloadData />,
        },
      ]
    : []),

  ...(featureEnabled["deleteData"] === true
    ? [
        {
          path: "/delete-account",
          element: <DeleteAccount />,
        },
      ]
    : []),

  // security and sub pages
  ...(!securityDisabled
    ? [
        {
          path: "/security",
          element: <Security />,
          primary: true,
        },
      ]
    : []),

  ...(!securityDisabled
    ? [
        {
          path: "/",
          element: <Security />,
          primary: true,
        },
      ]
    : []),

  ...(featureEnabled["changePassword"] === true
    ? [
        {
          path: "/change-password",
          element: <ChangePassword />,
        },
      ]
    : []),

  ...(featureEnabled["recoveryEmail"] === true
    ? [
        {
          path: RECOVERY_EMAIL_PATH,
          element: <RecoveryEmail />,
        },
      ]
    : []),

  ...(featureEnabled["permissions"] === true
    ? [
        {
          path: MANAGE_PERMISSIONS_PATH,
          element: <Permissions />,
        },
      ]
    : []),

  // terms and conditions
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/create-template-xyz",
    element: <CreateTemplate />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },

  // Profile

  ...(featureEnabled["profileInfo"] === true
    ? [
        {
          path: "/profile",
          element: <Profile />,
        },
      ]
    : []),

  ...(featureEnabled["connectedAccounts"] === true
    ? [
        {
          path: "/external-logins",
          element: <ExternalLogins />,
        },
      ]
    : []),

  // TFA
  ...(!tfaDisabled && tfaEnabled
    ? [
        {
          path: TFA_PATH,
          element: <TwoFactorAuthentication />,
        },
      ]
    : []),

  ...(featureEnabled["smsVerification"] === true && tfaEnabled
    ? [
        {
          path: TFA_TEXT_MESSAGE_PATH,
          element: <TextMessage />,
        },
      ]
    : []),

  ...(featureEnabled["emailVerification"] === true && tfaEnabled
    ? [
        {
          path: TFA_EMAIL_PATH,
          element: <EmailAuthenticator />,
        },
      ]
    : []),

  ...(featureEnabled["authAppVerification"] === true && tfaEnabled
    ? [
        {
          path: TFA_AUTH_APP_PATH,
          element: <AuthenticatorApp />,
        },
      ]
    : []),

  // Subscriptions
  ...(featureEnabled["subscriptions"] === true
    ? [
        {
          path: SUBSCRIPTIONS_PATH,
          element: <Subscriptions />,
          permissions: [betaTester],
        },
      ]
    : []),

  ...(featureEnabled["subscriptions"] === true
    ? [
        {
          path: SUBSCRIPTIONS_LIST_PATH,
          element: <SubscriptionsList />,
          permissions: [betaTester],
        },
      ]
    : []),

  // Payments & History
  ...(featureEnabled["payments"] === true
    ? [
        {
          path: PAYMENTS_PATH,
          element: <Payments />,
          permissions: [betaTester],
        },
      ]
    : []),

  ...(featureEnabled["payments"] === true
    ? [
        {
          path: PAYMENTS_LIST_PATH,
          element: <PaymentsList />,
          permissions: [betaTester],
        },
      ]
    : []),

  // Gifts & Vouchers
  ...(featureEnabled["gifts"] === true
    ? [
        {
          path: GIFTS_PATH,
          element: <Gifts />,
          permissions: [betaTester],
        },
      ]
    : []),

  ...(featureEnabled["gifts"] === true
    ? [
        {
          path: GIFTS_PATH_LIST,
          element: <GiftsList />,
          permissions: [betaTester],
        },
      ]
    : []),

  // Gifts & Vouchers

  ...(featureEnabled["gifts"] === true
    ? [
        {
          path: SITE_LICENCES_PATH,
          element: <SiteLicenceList />,
          permissions: [betaTester],
        },
      ]
    : []),
  ...(featureEnabled["gifts"] === true
    ? [
        {
          path: SITE_LICENCE_USERS_PATH,
          element: <SiteLicenceUsers />,
          permissions: [betaTester],
        },
      ]
    : []),

  {
    path: TFA_RECOVERY_CODES_PATH,
    element: <RecoveryCodes />,
  },
  // Manage Devices

  ...(featureEnabled["manageDevices"] === true
    ? [
        {
          path: MANAGE_DEVICES_PATH,
          element: <ManageDevices />,
        },
        {
          path: `${MANAGE_DEVICES_PATH}/:sessionId/*`,
          element: <DeviceDetail />,
        },
        // {
        //   path: DEVICE_DETAIL_PATH,
        //   element: <DeviceDetail />,
        // },
      ]
    : []),
  {
    path: "/custom-page/:slug",
    element: <Models />,
  },
  {
    path: "/custom-page/:slug/:itemId",
    element: <ModelItemDetails />,
  },
];
