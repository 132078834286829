import { FC, useEffect } from 'react'

import { format, isValid } from 'date-fns'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LiveError, LivePreview, LiveProvider } from 'react-live'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

//@ts-ignore
import Icon from '@gjirafatech/gjirafa-icons/Icon'

import DatePicker from 'react-date-picker'
import { PhotoProfileModal } from '../../components/account'
import { useUserContext } from '../../context/UserContext'
import {
  useFetch,
  useHasPasswordApi,
  usePreviewMode,
  useRenderPath,
  useToggle
} from '../../hooks'
import { templatesApiUrl } from '../../utils'

export const Profile: FC = () => {
  const {
    user,
    setUserData,
    picture,
    loading: getUserLoading
  } = useUserContext()

  const { visible, toggle } = useToggle()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { isPreviewMode, templateSearchParam } = usePreviewMode()
  const { renderPath } = useRenderPath(isPreviewMode)

  const { gender, passwordLastChanged } = !!user && user
  const { apiCall: updateUserProfile, loading } = useFetch('post')
  const { hasPassword } = useHasPasswordApi(true)

  const alias = pathname.replace('/', '')

  const {
    apiCall: getProfileTemplate,
    response: profileTemplate,
    loading: profileTemplateLoading
  } = useFetch('get')

  useEffect(() => {
    getProfileTemplate(templatesApiUrl(alias, isPreviewMode))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const methods = useForm()
  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors }
  } = methods

  let d: string = !!user?.birthdate && user?.birthdate?.split('/')
  let userBirthdate = new Date(+d[2], +d[1] - 1, +d[0])

  useEffect(() => {
    const userObj = {
      ...user,
      birthdate: isValid(userBirthdate) ? userBirthdate : null
    }

    if (user) reset(userObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, user])

  const onUpdateUserProfile = (data: any) => {
    const profileFields = {
      ...data,
      firstName: data?.firstName?.trim(),
      lastName: data?.lastName?.trim(),
      email: user?.email,
      phoneNumber: data.phoneNumber?.trim() || null,
      birthdate: !!data?.birthdate
        ? format(data?.birthdate, 'dd/MM/yyyy')
        : null
    }

    updateUserProfile(
      'ManageApi/UserProfile',
      profileFields,
      (response) => {
        toast.success(t('profile.profileUpdatedSuccessfully'))
        setUserData({
          ...response,
          birthdate: !!data?.birthdate
            ? format(data?.birthdate, 'dd/MM/yyyy')
            : null
        })
        navigate('/')
      },
      (error) => {
        const response = error?.response?.data

        if (!!response?.errors?.FirstName) {
          toast.error(t(response?.errors?.FirstName[0]))
        }

        if (!!response?.errors?.LastName) {
          toast.error(t(response?.errors?.LastName[0]))
        }

        if (!!response?.errors?.PhoneNumber) {
          toast.error(t(response?.errors?.PhoneNumber[0]))
        }
      }
    )
  }

  const scope = {
    onUpdateUserProfile,
    Controller,
    NavLink,
    Icon,
    picture,
    getUserLoading,
    DatePicker,
    control,
    errors,
    loading,
    hasPassword,
    gender,
    passwordLastChanged,
    handleSubmit,
    register,
    renderPath,
    t,
    user,
    toggle,
    format
  }

  useEffect(() => {
    if (templateSearchParam === 'change-profile-photo') toggle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('profile.profileInfo')}</title>
        <style>{profileTemplate?.css}</style>
      </Helmet>

      {profileTemplateLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <LiveProvider code={profileTemplate?.html || '<></>'} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}

      {visible && <PhotoProfileModal toggle={toggle} visible={visible} />}
    </>
  )
}
