import { NavLink, useParams } from 'react-router-dom'
//@ts-ignore
import Icon from '@gjirafatech/gjirafa-icons/Icon'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LiveError, LivePreview, LiveProvider } from 'react-live'
import { toast } from 'react-toastify'
import { MainContainer } from '../../containers'
import { useFetch, usePreviewMode } from '../../hooks'
import {
  USER_PROFILE_API_HEADERS,
  USER_PROFILE_CLIENT_API,
  detailsTemplateApiUrl,
  history
} from '../../utils'

export const ModelItemDetails = () => {
  const { slug, itemId } = useParams()
  const [itemDetails, setItemDetails] = useState<any>(null)
  const { isPreviewMode } = usePreviewMode()

  const {
    apiCall: getModelDetails,
    response: modelDetails,
    loading
  } = useFetch('get')
  const { apiCall: executeAction } = useFetch('get')

  const {
    apiCall: getModelDetailsTemplate,
    response: modelDetailsTemplate,
    loading: modelDetailsTemplateLoading
  } = useFetch('get')

  useEffect(() => {
    if (!!itemId) {
      getModelDetails(
        `${USER_PROFILE_CLIENT_API}/${slug}?modelId=${itemId}`,
        USER_PROFILE_API_HEADERS
      )
      getModelDetailsTemplate(detailsTemplateApiUrl(slug || '', isPreviewMode))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId])

  useEffect(() => {
    if (!!modelDetails) {
      setItemDetails(modelDetails.userModelData[0])
    }
  }, [modelDetails])

  const executeModelAction = (actionUrl: string) => {
    executeAction(
      `${actionUrl}`,
      {},
      () => {
        getModelDetails(
          `${USER_PROFILE_CLIENT_API}/${slug}?modelId=${itemId}`,
          USER_PROFILE_API_HEADERS,
          (data) => {
            if (!data?.userModelData?.length)
              history.push(`/custom-page/${slug}`)
          }
        )
      },
      (errors) => {
        const errsDefault = errors?.response?.data?.errors?.default
        if (errsDefault)
          Object.values(errsDefault)?.forEach((val: any) => toast.error(val))
      }
    )
  }

  const scope = {
    itemDetails,
    NavLink,
    slug,
    Icon,
    executeModelAction
  }

  return (
    <>
      <Helmet>
        <title className="text-primary">{slug} Details</title>
        <style>{modelDetailsTemplate?.css}</style>
      </Helmet>
      <MainContainer>
        {!modelDetailsTemplateLoading && !loading && (
          <LiveProvider
            code={modelDetailsTemplate?.html || '<></>'}
            scope={scope}
          >
            <LivePreview />
            <LiveError />
          </LiveProvider>
        )}
      </MainContainer>
    </>
  )
}
